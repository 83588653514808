import React, { useState } from "react";
import { connect } from "react-redux";
import push_pin from "../../assets/images/push_pin.svg";
import { useNavigate } from "react-router-dom";
import { Loader } from "semantic-ui-react";
import { storeDocDetail } from "../../redux/actions";
// import { getProcedure } from "../apiCall";

const XauthResultDisplay = (props) => {
  const [disableButton1, setDisableButton1] = useState(false);
  const [disableButton2, setDisableButton2] = useState(false);
  const navigate = useNavigate();
  const toViewAuthDoc = (result) => {
    // logUserAction({
    //   documentId: result.documentId[0],
    //   query: props.searchedWord,
    //   route: 'opendocument',
    //   documentType: 'authored',
    //   documentTitle : result.procedureTitle[0]
    // });
    // props.storeDocDetail({
    //   authorData: JSON.stringify(result),
    //   authdoc: true,
    // });
    navigate(`/searchResultDetail/authored/${result.documentId[0]}`);
    // if (result.stepId) {
    //   const data = { params: { id: result._nest_parent_ } }; // id of procedure
    //   getProcedure(data).then((res) => {
    //     props.storeDocDetail({
    //     authorData: JSON.stringify({
    //       highlightId : result.stepId,
    //       ...result,
    //       ...res.data
    //     }),
    //     authdoc: true,
    //     });
    //     navigate("/searchResultDetail");
    //   });
    // } else {
    //   const data = { params: { id: result.id } }; // id of procedure
    //   getProcedure(data).then((res) => {
    //     props.storeDocDetail({
    //     authorData: JSON.stringify(res.data),
    //     authdoc: true,
    //     });
    //     navigate("/searchResultDetail");
    //   });
    // }
  };

  // function removetagsinSummary (summary){

  //   const stringWithoutSubstrings = summary.replace(/&[^;]+;/g, '');
  //   return stringWithoutSubstrings;

  // }
  const pinButtonClick = (result) => {
    if (props.pinnedDocs.length < 10) {
      props.pinTheDoc(result);
      setDisableButton1(true);
      setDisableButton2(false);
    } else {
      alert(
        "You have reached the maximum limit of pinned documents. Please unpin some documents before pinning new ones"
      );
    }
  };

  const decodeEntities = (html) => {
    const element = document.createElement("div");
    element.innerHTML = html;
    return element.innerText;
  };

  const getType = (type) => {
    if (type === "setupInstruction") {
      return "Setup Instruction";
    } else if (type === "operatingProcedure") {
      return "Operating Procedure";
    } else if (type === "PROMOTIONAL MATERIAL") {
      return "Promotional Material";
    } else if (type === "TECHNICAL NOTE") {
      return "Techical Note";
    } else {
      return type;
    }
  };
  const unPinButtonClick = (pinId) => {
    setDisableButton1(false);
    setDisableButton2(true);
    props.unpindoc(pinId);
  };
  function removeTags(str) {
    let summary = str;
    let searchQuery = props.searchedWord;
    let queryArray = searchQuery.split(" ");
    function removeTag(str) {
      if (str === null || str === "") return false;
      else {
        str = str.toString();
        // let stringWithoutSubstrings = str.replace(/&[^;]+;/g, '');
        // str = stringWithoutSubstrings;
      }

      return str.replace(/(<([^>]+)>)/gi, "").substring(0, 300) + "...";
    }
    summary = removeTag(summary);
    for (let i = 0; i < queryArray.length; i++) {
      summary = summary.replace(queryArray[i], `<b>${queryArray[i]}</b>`);
    }
    return props.getFirstTwoSentence(summary);
  }
  return (
    //when qa engine is there we might not need md:w-[83.34em] , it is set to make the width unique when there is no summary
    <div
      className="border shadow-md rounded-md ml-14 mr-14 mt-6 md:w-[83.34em]"
      key={props.result.id}
    >
      <div className="p-5 border-b-2">
        <div className="flex font-semibold text-lg mb-4 titleandpin">
          <div className="xauth-tag">
            <a class="ui blue ribbon label">authored</a>
          </div>
          <div className=" overflow-hidden overflow-ellipsis w-[100%] ">
            {props.result.procedureTitle[0]}
          </div>
          {props.pinnedDocs?.find(
            (item) => item.documentId === props.result.id
          ) ? (
            <div className="pinImage ">
              <button
                className="ml-auto w-8 h-8"
                disabled={disableButton2 || props.apiCallInProgress}
                onClick={() =>
                  unPinButtonClick(
                    props.pinnedDocs?.find(
                      (item) => item.documentId === props.result.id
                    ).pinId
                  )
                }
              >
                <img
                  className="h-7 m-[2px] rounded-full bg-[#1f97dc]  right-[4em] md:right-[26.5em]"
                  src={push_pin}
                  alt="push pin logo"
                />
              </button>
              {disableButton2 && (
                <div className="xauth-loader-pin">
                  <Loader active inline />
                </div>
              )}
            </div>
          ) : (
            <div className="pinImage ">
              <button
                className="ml-auto w-8 h-8  unpindoc"
                disabled={disableButton1 || props.apiCallInProgress}
                onClick={() => pinButtonClick(props.result)}
              >
                <img
                  className="h-7 m-[2px]  rotate-45 rounded-full bg-gray-200  right-[4em] md:right-[26.5em] "
                  src={push_pin}
                  alt="push pin logo"
                />
              </button>
              {disableButton1 && (
                <div className="xauth-loader-pin">
                  <Loader active inline />
                </div>
              )}
            </div>
          )}
        </div>
        <div className="flex">
          <div className=" p-1 pl-4 pr-4 rounded-3xl bg-[#FDF0E4] w-fit border-2 text-[#f48728] border-[#f48728] text-[9px] md:text-sm">
            Category : {props.result.category}
          </div>
          <div className="ml-3 p-1 pl-4 pr-4 rounded-3xl bg-[#FDF0E4] w-fit border-2 text-[#f48728] border-[#f48728] text-[9px] md:text-sm">
            Knowledge Source: {getType(props.result.documentType)}
          </div>
        </div>
      </div>
      <div className="p-5" style={{ cursor: "pointer" }}>
        <div
          onClick={() => toViewAuthDoc(props.result)}
          style={{ cursor: "pointer" }}
        >
          <div className="font-bold mb-3" style={{ fontFamily: "Inter" }}>
            Summary
          </div>
          {props.result.summary.length ? (
            <div
              className="text-sm"
              style={{ fontFamily: "Inter" }}
              dangerouslySetInnerHTML={{
                __html: decodeEntities(
                  props.result.summary.replace(/<[^>]*>/g, "")
                ),
              }}
            >
              {/* {removetagsinSummary(props.result.summary.replace(/<[^>]*>/g, ''))} */}
            </div>
          ) : (
            <div
              className="text-sm"
              style={{ fontFamily: "Inter" }}
              dangerouslySetInnerHTML={{
                __html: decodeEntities(
                  removeTags(props.result.procedureDescription[0])
                ),
              }}
            ></div>
          )}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    searchedWord: state.searchedWord,
    pinnedSearches: state.pinnedSearches,
  };
};

export default connect(mapStateToProps, {
  storeDocDetail,
})(XauthResultDisplay);
